import { UserRole } from '~/types';
import { IUserFormValues } from '~/ui/components/reusable/UserForm/types';

const initialValues: IUserFormValues = {
  roleId: UserRole.HealthManager,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  shouldEditPassword: true,
  password: '',
  passwordConfirm: '',
  programIds: [],
  permissions: [],
  isActive: true,
  isMfaEnabled: true,
  isMobileEnabled: false,
  hasAgreed: false,
  receiveMissedReadingNotifications: false,
};

export default initialValues;
