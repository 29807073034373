import { IUser } from '~/services/api/users/types';
import { IUserFormValues } from '~/ui/components/reusable/UserForm/types';

const getInitialValues = (user: IUser): IUserFormValues => {
  const {
    roleId,
    firstName,
    lastName,
    email,
    phone,
    programIds,
    permissions,
    isActive,
    isMfaEnabled,
    isMobileEnabled,
    hasAgreed,
    receiveMissedReadingNotifications,
  } = user;

  return {
    roleId,
    firstName,
    lastName,
    email,
    phone,
    shouldEditPassword: false,
    password: '',
    passwordConfirm: '',
    programIds,
    permissions,
    isActive,
    isMfaEnabled,
    isMobileEnabled,
    hasAgreed,
    receiveMissedReadingNotifications,
  };
};

export default getInitialValues;
